import React from 'react';
// import logo from './logo.svg';
import logo from './app_with_device.png';
import appstorebadge from './app-store-badge.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>CalcuBattle</h1>
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          CalcuBattle is an online multiplayer game to have fun and train your
          calculation skillz.
        </p>
        <p>Download now!</p>
        <img width="300" src={appstorebadge} className="App-logo" alt="download app store" />
        <p>For support, please send an email to: info@dirkpostma.nl</p>

        <iframe
          title="video instructions"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/7-VNraOLYiM"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </header>
    </div>
  );
}

export default App;
